import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import * as d3 from "d3";
import Chart from "../../components/d3/Chart";
import LoadMapsApi from "../../components/maps/LoadMapsApi";
import MapWithMarkers from "../../components/maps/MapWithMarkers";
import MapDrawn from "../../components/maps/MapDrawn";
import data from "../../components/d3/data";
import { mapCenterNZ, mapCenterCZ, mapCenterEverest, markerPositionsNZ, markerPositionsCZ, markerPositionsEverest } from "../../components/maps/data";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <LoadMapsApi />
      <MDXTag name="h5" components={components}><MDXTag name="em" components={components} parentName="h5">{`Image credit above goes to `}<MDXTag name="a" components={components} parentName="em" props={{
            "href": "https://www.behance.net/furryhead"
          }}>{`furryhead`}</MDXTag>{`, see the `}<MDXTag name="a" components={components} parentName="em" props={{
            "href": "https://www.behance.net/gallery/29307801/2001-A-Space-Odyssey-animated-Gif"
          }}>{`original creative here`}</MDXTag>{`.`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`This is part 3 of a 3-part series about using Google Maps and D3 to create an elevation graph that responds dynamically with user interaction. Here are all 3 posts:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 1: Google Maps`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 2: D3 for Data Visualization`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-03-27-draw-an-interactive-elevation-chart-with-d3-and-react-pt-3"
          }}>{`Part 3: Creating User Interaction`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`In this section, we’ll finish building this. Try hovering over the graph to see how our users can interact with this component!`}</MDXTag>
      <MapDrawn type="withChart" center={mapCenterCZ} markerPositions={markerPositionsCZ} title="Prague" theme="retro" zoom={11} height={400} width={553} />
      <Chart type="withMap" targetNode={"#chart6"} data={data.fakeData6} transform="translate(47.5, 5)" ticks={true} gridlines={true} lineColor="84, 53, 16" fillColor="131, 140, 94" background="#fff" mapName="__map__Prague" height={200} width={550} viewBox="0 0 525 175">
  <div id="chart6" />
      </Chart>
      <MDXTag name="p" components={components}>{`Alright. Let’s get started.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Creating User Interaction`}</MDXTag>
      <MDXTag name="p" components={components}>{`In this part (part 3), we’ll be connecting our Map and Graph components, updating them both dynamically based on user input.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here is where we left off in Part 1:`}</MDXTag>
      <MapWithMarkers type="elevationAlongPath" center={mapCenterNZ} markerPositions={markerPositionsNZ} title="Baldwin St – Dunedin, NZ" theme="night" zoom={13.8} />
      <MDXTag name="p" components={components}>{`On the map above, go ahead and click:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="inlineCode" components={components} parentName="li">{`Show Markers`}</MDXTag>{` –>`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="inlineCode" components={components} parentName="li">{`Draw Path`}</MDXTag>{` –>`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="inlineCode" components={components} parentName="li">{`Elevation Samples`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`and then check your console to see what the response from the Google API looks like.`}</MDXTag>
      <MDXTag name="p" components={components}>{`(In case you’re on mobile, here’s a sample of the elevations that the Google Maps API returns to us:)`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`{elevation: 35.52785110473633, location: _.P, resolution: 19.08790397644043}
{elevation: 35.73416519165039, location: _.P, resolution: 19.08790397644043}
// 96 more...
{elevation: 80.05186462402344, location: _.P, resolution: 19.08790397644043}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`We can ignore the resolution field for now, and we’ll discuss what the `}<MDXTag name="inlineCode" components={components} parentName="p">{`_.P`}</MDXTag>{` value means soon.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="em" components={components} parentName="p">{`Remember`}</MDXTag>{`: We asked for 100 samples along our pathline, so we’re working with 100 data points, not just the 6 markers that display in our map!`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`And here is where we left off in Part 2:`}</MDXTag>
      <Chart targetNode={"#chart5"} data={data.fakeData5} transform="translate(47.5, 5)" ticks={true} gridlines={true} lineColor="26, 44, 46" fillColor="103, 148, 115" background="rgb(144, 153, 167, 0.15)">
  <div id="chart5" />
      </Chart>
      <MDXTag name="p" components={components}>{`Cool! So what’s next?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Next we’re going to connect our map and our elevation chart so when a user hovers over the chart, a “blip” appears dynamically on the map to show the user where on the map the user will experience that particular elevation.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s jump right in! ✨`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Getting Our Distances`}</MDXTag>
      <MDXTag name="p" components={components}>{`So we have our elevations, but how do we get distances? We need 100 of them to populate the x-axis so our every elevation on the y-axis maps to a corresponding value.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For our purposes, each distances will represent how far that elevation sample is from the starting point, which is our first map marker.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We will also need to keep a reference to the map around so we can ask it where a particular longitude and latitude is on the map (that way we know where on the screen to draw our “blip”).`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our component’s signature should look like this when we’re done:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`<Chart
  elevations={this.state.elevations}
  markers={this.state.markers}
  mapName={this.props.mapRef}
/>
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Where `}<MDXTag name="inlineCode" components={components} parentName="p">{`map`}</MDXTag>{` is a reference to our Google Maps instance, `}<MDXTag name="inlineCode" components={components} parentName="p">{`markers`}</MDXTag>{` are the Google Map Markers that together comprise our polyline, and `}<MDXTag name="inlineCode" components={components} parentName="p">{`elevations`}</MDXTag>{` is an array of 100 objects with the following shape:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`{
  elevation: float!
  location: LagLng
  resolution: float!
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`So how are we going to get those distances?`}</MDXTag>
      <MDXTag name="p" components={components}>{`First we have to talk about what this `}<MDXTag name="inlineCode" components={components} parentName="p">{`_.P`}</MDXTag>{` thing is.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Getting an Elevation’s Coordinates`}</MDXTag>
      <MDXTag name="p" components={components}>{`The `}<MDXTag name="inlineCode" components={components} parentName="p">{`_.P`}</MDXTag>{` value that you see at the `}<MDXTag name="inlineCode" components={components} parentName="p">{`location`}</MDXTag>{` key is an instance of the Google Maps `}<MDXTag name="inlineCode" components={components} parentName="p">{`LatLng`}</MDXTag>{` class (`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng"
        }}>{`see the docs on LatLng class`}</MDXTag>{`).`}</MDXTag>
      <MDXTag name="p" components={components}>{`We can use this object to get an elevation’s corresponding latitude and longitude coordinates.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="strong" components={components} parentName="p">{`That the Elevations API returns a `}<MDXTag name="inlineCode" components={components} parentName="strong">{`LatLng`}</MDXTag>{` instance is the key to making all of this work.`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`If we want to get the coordinates of our first object in our `}<MDXTag name="inlineCode" components={components} parentName="p">{`Chart`}</MDXTag>{` component, we do:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`class Chart extends React.Component {
  render() {
    return (
      <div>
        <p>1st Elevation Latitude: {this.props.data[0].location.lat()}</p>
        <p>1st Elevation Longitude: {this.props.data[0].location.lng()}</p>
      </div>
    )
  }
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Among other things, the `}<MDXTag name="inlineCode" components={components} parentName="p">{`LatLng`}</MDXTag>{` class comes with 2 methods that simply return the latitude or longitude value they’re holding.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Test it out yourself!`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you want to test it out yourself, I’ve added the map above to `}<MDXTag name="inlineCode" components={components} parentName="p">{`window`}</MDXTag>{` at this address:`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`window.__map__Baldwin_St___Dunedin__NZ`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If you open the console, you can run any method you’d like. Try running:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`window.__map__Baldwin_St___Dunedin__NZ.center.lat() // -45.849184470199674
window.__map__Baldwin_St___Dunedin__NZ.center.lng() // 170.5342357575057
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Those are the coordinates for the center of our map! `}<MDXTag name="inlineCode" components={components} parentName="p">{`GoogleMap.center`}</MDXTag>{` is an instance of the `}<MDXTag name="inlineCode" components={components} parentName="p">{`LatLng`}</MDXTag>{` class.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Okay, so we can get coordinates. But we’re creating an area chart (basically a line graph with shading under the line), which means our x-axis needs to represent a scale (do you remember `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/d3/d3-scale#linear-scales"
        }}>{`what kind of scale?`}</MDXTag>{`).`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our `}<MDXTag name="inlineCode" components={components} parentName="p">{`xScale`}</MDXTag>{` is distance from the polyline’s point of origin, `}<MDXTag name="em" components={components} parentName="p">{`along the polyline`}</MDXTag>{`. This is important because we’re assuming that the polyline represents a user’s trip, and so a particular point on our graph tells us `}<MDXTag name="em" components={components} parentName="p">{`how far along the trip`}</MDXTag>{` the user can expect to encounter a particular elevation.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`So our scales are:`}</MDXTag></MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="inlineCode" components={components} parentName="li">{`x`}</MDXTag>{` = distance from origin along polyline`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="inlineCode" components={components} parentName="li">{`y`}</MDXTag>{` = elevation at point`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Getting Our Distances`}</MDXTag>
      <MDXTag name="p" components={components}>{`At first my team struggled with this.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sure, the Google Maps API does allow you to pass an “array” of coordinate pairs and returns an array of distance results, `}<MDXTag name="em" components={components} parentName="p">{`but we’d only be getting the beeline distance`}</MDXTag>{`, which is different than what we’re after.`}</MDXTag>
      <MDXTag name="p" components={components}>{`To get around this, we decided to calculate the distance between each marker to come up with the total distance along our pathline, then divide that number by 100 (the arbitrary number of samples we decided to take, saved as a constant).`}</MDXTag>
      <MDXTag name="p" components={components}>{`Although this isn’t 100% perfect, the `}<MDXTag name="inlineCode" components={components} parentName="p">{`getElevationAlongPath`}</MDXTag>{` function Google Maps provides returns as many samples as you ask for along the polyline that you pass it.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It’s not perfect because Google Maps approximates, rather than calculates, the elevation based on the surrounding topography (the `}<MDXTag name="inlineCode" components={components} parentName="p">{`resolution`}</MDXTag>{` tells us how accurate the return value is).`}</MDXTag>
      <MDXTag name="p" components={components}>{`But it’s pretty damn close (as you can see in the final product). So we considered our users and decided that and we’re comfortable with the tradeoff:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="strong" components={components} parentName="p">{`The tradeoff:`}</MDXTag>{` Less app complexity + fewer API calls in exchange for occasional, slight inaccuracies.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`That means we can divide our total distance by the number of elevation samples to create a unit, and then multiply that unit by the elevation’s index (plus 1) to get an elevation’s cumulative distance from the point of origin.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here’s the code:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`export const numOfSamples = 100
const startDistance = 0
const endDistance = distances.reduce((acc, curr) => acc + curr, startDistance)
const sampleUnit = endDistance / numOfSamples

const makeData = data =>
  data.reduce((acc, curr, i) => {
    const dist = sampleUnit * (i + 1)
    return acc.concat({ x: dist, y: curr.elevation, ...curr })
  }, [])
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Calling `}<MDXTag name="inlineCode" components={components} parentName="p">{`makeData`}</MDXTag>{` and passing in the array of elevations that we received on props, we get an array of objects with this shape:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`{
  x: float! // cululative distance from origin along path
  y: float! // elevation in meters at point
  location: LagLng!
  resolution: float!
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Bingo. We have our distances.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Get Screen Coordinates`}</MDXTag>
      <MDXTag name="p" components={components}>{`But there’s still one big problem we haven’t solved ye yett:`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`How are we supposed to draw our “blip” on the map?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`At first we tried using the Marker API to create a marker from a given location, only to destroy it and redraw a new one when the user’s mouse moves.`}</MDXTag>
      <MDXTag name="p" components={components}>{`That ended up creating a very choppy user experience, not to mention the event handler was lossy — the Markers API was not called, so sometimes a marker would render and sometimes it would not be deleted.`}</MDXTag>
      <MDXTag name="p" components={components}>{`However it wasn’t hard to implement, so we tried it out. Each elevation comes with a set coordinates, so all we do is `}<MDXTag name="inlineCode" components={components} parentName="p">{`new google.maps.Marker(...)`}</MDXTag>{`, passing in the coordinate set.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here a silly video of how our first iteration turned out — you can tell we were getting a little loopy from lack of sleep:`}</MDXTag>
      <iframe className="youtube-video" width="720" height="480" src="https://www.youtube.com/embed/IKNc7kCjEc8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      <MDXTag name="p" components={components}>{`So yeah. Markers were out.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`If not markers, how?`}</MDXTag>
      <MDXTag name="p" components={components}>{`As far as we could ascertain, the Markers API was the only way to render an element at a particular point on the map. Sure, technically the API affords us other SVG options, but they all suffer from the same choppy UX.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We poured over the Google Maps documentation, looking for an escape hatch. Some way to “map” a set of coordinates that exist on the map canvas to its corresponding set of screen-x and screen-y coordinates in the browser window.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We were feeling pretty stuck until we stumbled across the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/javascript/reference/coordinates#Point"
        }}>{`Point class`}</MDXTag>{`, which represents a point on a two-dimensional plane.`}</MDXTag>
      <MDXTag name="p" components={components}>{`From there we found the `}<MDXTag name="inlineCode" components={components} parentName="p">{`fromLatLngToPoint`}</MDXTag>{` class, and Googling it led us to this short, wonderful `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng"
        }}>{`blog post by Krasimir Tsonev`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The magic function:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`const fromLatLngToPoint = (latLng, map) => {
  const topRight = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getNorthEast())
  const bottomLeft = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getSouthWest())
  const scale = Math.pow(2, map.getZoom())
  const worldPoint = map
    .getProjection()
    .fromLatLngToPoint(new window.google.maps.LatLng(latLng))
  const point = new window.google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale
  )
  return point
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The `}<MDXTag name="inlineCode" components={components} parentName="p">{`Math.pow(2, map.getZoom())`}</MDXTag>{` on line 8 was exactly what we needed to make the conversion.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Because we pass the map as an argument each time, `}<MDXTag name="em" components={components} parentName="p">{`even if the user drags or zooms the map mouse moves, `}<MDXTag name="strong" components={components} parentName="em">{`we always get the fresh and current screen x- and y-coordinates at a given position`}</MDXTag></MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Almost done. Now that we know `}<MDXTag name="em" components={components} parentName="p">{`where`}</MDXTag>{` to draw the blip, let’s draw the damn thing.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Drawing the “Blip”`}</MDXTag>
      <MDXTag name="p" components={components}>{`We’ll draw the blip inside our `}<MDXTag name="inlineCode" components={components} parentName="p">{`mousemove`}</MDXTag>{` function:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`function mousemove() {
  const x0 = xScale.invert(d3.mouse(this)[0])
  const i = bisect(data, x0, 1)
  const d0 = data[i - 1]
  const d1 = data[i]
  const d = !d1 ? d0 : x0 - d0.x > d1.x - x0 ? d1 : d0
  crossBar.attr("transform", \`translate(\${xScale(d.x)}, 0)\`)
  crossBar.select("text").text(d3.format(".1f")(metersToMiles(d.x)) + "mi")
  infoBox.attr("transform", \`translate(\${xScale(d.x) + 10}, 12.5)\`)
  infoBox
    .select(".infoBoxElevationValue")
    .text(d3.format(",.0f")(metersToFeet(d.y)) + "ft")

  /*** NEW STUFF ***/
  const { x: px, y: py } = fromLatLngToPoint(d.location, this.state.map)
  blip.style("transform", \`translate3d(\${px}px, \${py}px, 0px)\`)

  return null
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`And finally, we need to toggle the blip’s display in our `}<MDXTag name="inlineCode" components={components} parentName="p">{`mouseover`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`mouseleave`}</MDXTag>{` events. Just 2 lines of code does the trick:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`svg
  .append("rect")
  .attr("class", "chartOverlay")
  .attr("width", this.width)
  .attr("height", this.height)
  .on("mouseover", function() {
    crossBar.style("display", null)
    infoBox.style("display", null)
    /*** NEW ***/
    blip.style("display", null)
  })
  .on("mouseout", function(e) {
    crossBar.style("display", "none")
    infoBox.style("display", "none")
    /*** NEW ***/
    blip.style("display", "none")
  })
  .on("mousemove", mousemove)
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`And we’re done it! That’s a wrap 💯`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s take one last look at what we made together:`}</MDXTag>
      <MapDrawn type="withChart" center={mapCenterEverest} markerPositions={markerPositionsEverest} title="Mt. Everest" theme="retro" zoom={13} height={400} width={553} />
      <Chart type="withMap" targetNode={"#chart7"} data={data.fakeData7} transform="translate(47.5, 5)" ticks={true} gridlines={true} lineColor="84, 53, 16" fillColor="131, 140, 94" background="#fff" mapName="__map__Mt__Everest" height={200} width={550} viewBox="0 0 525 175">
  <div id="chart7" />
      </Chart>
      <MDXTag name="p" components={components}>{`Check out the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/Lambda-School-Labs/LabsPT1_bkwds/blob/master/client/src/components/ElevationChart.js"
        }}>{`source code for the ElevationChart component here`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`These components were part of our capstone project at Lambda School. If you have any questions about the project or my experience there, feel free to reach out to me at `}<a href="mailto:ahrjarrett@gmail.com">{`ahrjarrett@gmail.com`}</a>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Until next time.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/2019-03-27-draw-an-interactive-elevation-chart-with-d3-and-react-pt-3",
  "date": "2019-03-27",
  "title": "Draw an Interactive Elevation Chart with D3 & React, Part 3",
  "image": "2019-03-27-draw-an-interactive-elevation-chart-with-d3-and-react-pt-3.gif",
  "sharpImage": false,
  "imgOverlay": "linear-gradient(to top, rgba(195, 20, 50, 0.75), rgba(36, 11, 54, 0.75))",
  "tags": ["react", "d3", "javascript", "google maps", "data-visualization"],
  "excerpt": "Part 3: Data visualization is too fun. My team made an app that consumes a user’s trip data and uses it to draw cool stuff. Here’s how we used React, D3 and Google Maps to do it.",
  "published": true
};
      